import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {DateTime} from "luxon";
import {Link} from "react-router-dom";
import PermissionUtils from "Utilities/permissionUtils";
import {selectActiveOrg, selectActiveOrgLicense} from "Store/selectors";
import SubscriptionRemote, {LinkOutputDto, ManageSubscriptionDto} from "../../../Store/async/subscription.remote";
import useLoadingProgress from "../../../Hooks/useLoadingProgress";

type LinkDto = {
    url: string
}

const ContainerVisualization = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  a {
    text-decoration: none;
  }

  .items-container {
    display: grid;
    //width: 100%;
    //height: 100%;
    background-color: #444;
    gap: 1rem;
    padding: 0.9rem 2rem;
  }

  button {

    display: block;
    height: 2rem;
    width: 200px;

    margin: auto;
    margin-top: 10px;

    font-size: 1.25rem;
    line-height: 0;
  }

  .plan-item {
    display: grid;
    gap: 1rem;
    grid-template-columns: 8ch 21ch;

    align-items: center;
    justify-content: center;
    height: 34px;

    span {
      font-size: 1.25em;
    }

    span:nth-child(2) {
      font-size: 1.35rem;
    }

    input, select {
      font-size: 1.2em;
    }
  }
`

export function DrawLicenseVisualization()
{
    const activeLicense = useAppSelector(selectActiveOrgLicense)
    const organization = useAppSelector(selectActiveOrg);

    const activePlan = activeLicense?.plan;
    const edit = organization && PermissionUtils.hasEdit(organization.permissions);

    const progress = useLoadingProgress();
    const dispatch = useAppDispatch();

    function getStatus(): string
    {
        let status = "";

        if (!activeLicense)
            return "-";

        if (!activeLicense.isSubscription)
            return activeLicense.isActive ? "Active" : "Disabled";

        status = activeLicense.subscriptionStatus;
        let split: Array<string> = status.split("_");

        for (let i = 0; i < split.length; i++)
        {
            split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
        }

        return split.join(" ");
    }

    function getDate(dateIso?: string): string
    {
        if (!dateIso)
            return "-";

        // the date is in universal time
        if (!dateIso.endsWith("Z"))
            dateIso += "Z";

        return DateTime
            .fromISO(dateIso)
            .toLocaleString();
    }

    async function billingClick()
    {
        if (activeLicense)
        {
            const input: ManageSubscriptionDto = {
                licenseId: activeLicense.id,
                returnUrl: window.location.href
            };
            progress.start()
            dispatch(SubscriptionRemote.manage(input))
                .then(response =>
                {
                    const link = response.payload as LinkOutputDto;
                    if (link)
                        document.location.assign(link.url)
                })
                .finally(() => progress.stop());
        }
    }

    async function cancelSubscriptionClick()
    {
        if (activeLicense)
        {
            progress.start()
            dispatch(SubscriptionRemote.cancelFreeSubscription(activeLicense.id))
                .finally(() =>
                {
                    progress.stop();
                });
        }
    }

    return (
        <ContainerVisualization>
            <div>
                <div className="items-container">
                    {/* Active Plan */}
                    <div className="plan-item">
                        <span className="c-xr"> Plan: </span>
                        <span>{activePlan ? activePlan.name : "-"}</span>
                    </div>

                    {/* Creation Date */}
                    <div className="plan-item">
                        <span className="c-xr"> Seats: </span>
                        <span>
						{
                            activeLicense?.seats
                        }
					</span>
                    </div>

                    {/* Expiration Date */}
                    <div className="plan-item">
                        <span className="c-xr"> Expire: </span>
                        <span>
						{
                            getDate(activeLicense?.expirationDate)
                        }
					</span>
                    </div>

                    {/* Active dropdown */}
                    <div className="plan-item">
                        <span className="c-xr"> Status: </span>
                        <span>{getStatus()}</span>
                    </div>
                </div>

                {
                    activeLicense ?
                        (
                            activeLicense.isFree ?
                                <button onClick={cancelSubscriptionClick}>
                                    Cancel
                                </button>
                                :
                                <button disabled={!edit || !activeLicense.isSubscription} onClick={billingClick}>
                                    Manage License
                                </button>
                        ) :
                        <Link to="/pricing" style={!edit ? {pointerEvents: "none"} : {}}>
                            <button disabled={!edit}>Try Or Buy</button>
                        </Link>
                }

            </div>
        </ContainerVisualization>
    )
}