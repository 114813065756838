import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Organization} from "Models";
import OrganizationsRemote from "Store/async/organizations.remote";

export const organizationAdapter = createEntityAdapter<Organization>({
    selectId: model => model.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export type LoadState = "Uninitialized" | "Loading" | "Completed"

export function getFirstActiveOrg(orgs: Organization[])
{
    const key = 'active-org';
    const firstId = localStorage.getItem(key);

    for (let i = 0; i < orgs.length; i++)
    {
        if (orgs[i].id == firstId)
            return orgs[i];
    }

    return orgs[0]
}

export const organizationsSlice = createSlice({
    name: 'Organizations',
    initialState: organizationAdapter.getInitialState({
        current: <Organization | null>null,
        loadState: <LoadState>"Uninitialized"
    }),
    reducers: {
        setActiveOrganization: (state, action: PayloadAction<string>) =>
        {
            const activeOrg = organizationAdapter.getSelectors().selectById(state, action.payload)

            if (activeOrg)
            {
                state.current = activeOrg
                localStorage.setItem('active-org', activeOrg.id)
            }
        },
        setThumbnail: (state, action: PayloadAction<string>) =>
        {
            const orgId = state.current!.id
            const blobUrl = action.payload;

            // Apply new thumb to active org
            if (state.current)
            {
                state.current.thumbnailUrl = blobUrl
            }

            // Apply new thumb to list item
            organizationAdapter.updateOne(state, {
                id: orgId,
                changes: {thumbnailUrl: blobUrl}
            })
        }
    },
    extraReducers: builder => builder
        .addCase(OrganizationsRemote.list.pending, (state, action) =>
        {
            state.loadState = "Loading"
        })
        .addCase(OrganizationsRemote.list.rejected, (state, action) =>
        {
            state.loadState = "Uninitialized"
        })
        .addCase(OrganizationsRemote.list.fulfilled, (state, action) =>
        {
            state.current = null
            state.loadState = "Completed"
            organizationAdapter.removeAll(state)
            organizationAdapter.addMany(state, action.payload)

            if (action.payload.length == 0)
                return

            state.current = getFirstActiveOrg(organizationAdapter.getSelectors().selectAll(state));
        })

        .addCase(OrganizationsRemote.update.fulfilled, (state, action) =>
        {
            const {id, ...data} = action.meta.arg

            // Replace active organization data with the ones I had sent
            if (state.current?.id === id)
            {
                Object.assign(state.current, data)
            }

            // Update said organization from the list
            organizationAdapter.updateOne(state, {
                id: action.meta.arg.id,
                changes: data
            })
        })

        .addCase(OrganizationsRemote.create.fulfilled, (state, action) =>
        {
            const newOrg = action.payload;

            state.current = newOrg
            organizationAdapter.addOne(state, newOrg)
        })
        .addCase(OrganizationsRemote.delete.fulfilled, (state, action) =>
        {
            const deletedOrgId = action.meta.arg.id

            // Is the current organization? (probably yes)
            if (state.current?.id === deletedOrgId)
            {
                state.current = null
            }

            // Remove if from the orgs collection
            organizationAdapter.removeOne(state, deletedOrgId)
        })
        .addCase(OrganizationsRemote.downloadThumbnail.fulfilled, (state, action) =>
        {
            const organization: Organization = action.meta.arg;
            const orgId = organization.id

            const url = action.payload;

            // Update said organization from the list
            organizationAdapter.updateOne(state, {
                id: orgId,
                changes: {
                    thumbnailUrl: url
                }
            })
        })
})

export const organizationActions = organizationsSlice.actions;